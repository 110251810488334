<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">
                {{ $t("CASH_REGISTERS.EDIT_CASH_REGISTER") }}
              </h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
          <div class="card-body">
            <cash-register-form
              :loading="loading"
              :cashRegisterData="cashRegister"
              :formErrors="formErrors"
              @cashRegisterSubmitted="handleSubmit"
              @formChanged="() => (alertLeave = true)"
            />
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import CashRegisterForm from "./partials/CashRegisterForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultCashRegister from "./defaultCashRegister";

export default {
  layout: "DashboardLayout",

  components: { CashRegisterForm },

  mixins: [alertLeave],

  data() {
    return {
      cashRegister: cloneDeep(defaultCashRegister),
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("cashRegisters/get", id);
        this.cashRegister = this.$store.getters["cashRegisters/cashRegister"];
        this.loading = false;
        if (!this.cashRegister.establishment) {
          this.cashRegister.establishment = defaultCashRegister.establishment;
        }
        if (!this.cashRegister.serviceCenter) {
          this.cashRegister.serviceCenter = defaultCashRegister.serviceCenter;
        }
        if (!this.cashRegister.cafeteria) {
          this.cashRegister.cafeteria = defaultCashRegister.cafeteria;
        }
        if (!this.cashRegister.warehouse) {
          this.cashRegister.warehouse = defaultCashRegister.warehouse;
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    goBack() {
      this.$router.push({ name: "List CashRegisters" });
    },

    viewCashRegister() {
      this.alertLeave = false;
      this.$router.push({
        name: "View CashRegister",
        params: { id: this.cashRegister.id },
      });
    },

    async handleSubmit(cashRegister) {
      this.loading = true;
      const cashRegisterData = cloneDeep(cashRegister);
      if (cashRegisterData.serviceCenter) {
        if (!cashRegisterData.serviceCenter.id) {
          delete cashRegisterData.serviceCenter;
        }
      }
      if (cashRegisterData.establishment) {
        if (!cashRegisterData.establishment.id) {
          delete cashRegisterData.establishment;
        }
      }
      if (cashRegisterData.cafeteria) {
        if (!cashRegisterData.cafeteria.id) {
          delete cashRegisterData.cafeteria;
        }
      }
      if (cashRegisterData.warehouse) {
        if (!cashRegisterData.warehouse.id) {
          delete cashRegisterData.warehouse;
        }
      }
      try {
        await this.$store.dispatch("cashRegisters/update", cashRegisterData);
        this.$notify({
          type: "success",
          message: this.$t("CASH_REGISTERS.CASH_REGISTER_UPDATED"),
        });
        this.viewCashRegister();
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
